import React from "react";

import { Flex, Box, Center, Tag, TagLabel } from "@chakra-ui/react";
import { Fade } from "@chakra-ui/react";
import ReactResizeDetector from "react-resize-detector";

import config from "../config";

import zieglerLogo from "../assets/companies/logo_ziegler.png";
import chatelLogo from "../assets/companies/logo_chatel.png";
import intertransLogo from "../assets/companies/logo_inter.png";
import satraLogo from "../assets/companies/logo_satra.png";
import specialRequestLogo from "../assets/companies/logo_sr.png";
import transLogo from "../assets/companies/logo_trans.png";
import transcoLogo from "../assets/companies/logo_transco.png";

import "./card.css";

const MATCH_LOGO_TO_COMPANY = {
  ziegler: zieglerLogo,
  satra: satraLogo,
  "special request by ziegler": specialRequestLogo,
  transco: transcoLogo,
  "trans service": transLogo,
  chatel: chatelLogo,
  intertrans: intertransLogo
};

export const CardWrapperFlip = ({
  cardTemplate,
  content,
  isCardFlipped,
  setCardFlipped,
  placeholder = "",
  showName = true,
  flipOnClick
}) => {
  const showValueOrPrompt = value => (value && value.length ? value : placeholder);
  const { colorScheme } = content.front;
  const colors = config.colorScheme[colorScheme];

  return (
    <ReactResizeDetector handleWidth handleHeight>
      {({ width }) => {
        const scale = Math.min(width / 640, 1);
        return (
          <Flex direction="column" align="center" justify="center" w="100%" h="100%">
            <div
              className="cardContainer "
              style={{
                pointerEvents: flipOnClick ? "" : "none",
                width: 640,
                height: 400,
                transform: `scale(${scale})`,
                padding: width < 640 ? 10 : 0
              }}
            >
              <div
                className={`card ${isCardFlipped ? "active" : "inactive"}`}
                onClick={() => setCardFlipped && setCardFlipped(isCardFlipped ? 0 : 1)}
              >
                <div className={`side front ${content.front.font}`}>
                  <Flex justify="center">
                    <Box w="100%" h="100%" position="absolute" top="0" bg={colors.bgGradient} />
                    <>
                      {cardTemplate.map(path => (
                        <img key={`card_image_${path}`} className="layer" src={`/cards/${path}`} alt="" />
                      ))}
                    </>
                    <img className="logo" src={MATCH_LOGO_TO_COMPANY[content.front.logo.toLowerCase()]} alt="" />
                    <h1 className="black-weight">{showValueOrPrompt(content.front.header)}</h1>
                  </Flex>
                </div>
                <div className={`side back ${content.back.font}`}>
                  <Flex
                    p="24px 48px"
                    direction="column"
                    justify="center"
                    h="100%"
                    bg={colors.bgBack}
                    color={colors.fontColor}
                  >
                    <h1>
                      {showName ? content.back.greeting : ""} {showValueOrPrompt(content.back.name)}
                    </h1>
                    <p className="wishes">{showValueOrPrompt(content.back.body)}</p>
                    <p className="signature">{showValueOrPrompt(content.back.signature)}</p>
                  </Flex>
                </div>
              </div>
            </div>
            <Center mt={8}>
              <Fade in={!!setCardFlipped}>
                <Tag
                  size={"lg"}
                  borderRadius="full"
                  variant="solid"
                  colorScheme="teal"
                  cursor="pointer"
                  onClick={() => setCardFlipped(isCardFlipped ? 0 : 1)}
                >
                  <TagLabel>Flip Card</TagLabel>
                </Tag>
              </Fade>
            </Center>
          </Flex>
        );
      }}
    </ReactResizeDetector>
  );
};
