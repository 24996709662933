import React, { useState, useEffect } from "react";
import { DEFAULT_FONT } from "../constants";

import {
  Box,
  Center,
  Text,
  Link,
  Tag,
  TagLabel,
  Flex,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton
} from "@chakra-ui/react";

import logo from "../assets/created_by_ZL.svg";
import config from "../config";

export const ZieglerLabsLogo = () => (
  <Box position="absolute" bottom={3} left={3}>
    <img src={logo} alt="" />
  </Box>
);

export const CreateNewButton = ({ resetCard, copyCard }) => {
  const [isOpen, setIsOpen] = useState(false);
  const open = () => setIsOpen(!isOpen);
  const close = () => setIsOpen(false);

  return (
    <Box position="absolute" top={3} left={3} zIndex={10}>
      <Popover placement="bottom" isOpen={isOpen} onClose={close}>
        <PopoverTrigger>
          <Tag size={"lg"} borderRadius="full" variant="solid" colorScheme="teal" cursor="pointer" onClick={open}>
            <TagLabel>New Card</TagLabel>
          </Tag>
        </PopoverTrigger>
        <PopoverContent w="240px">
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody fontSize="md">
            <Text fontSize="lg">Create new card.</Text>
            <Text fontSize="sm">Warning! All of the unsaved changes will be discarded.</Text>
          </PopoverBody>
          <PopoverFooter>
            <Button
              size="sm"
              colorScheme="green"
              onClick={() => {
                close();
                copyCard();
              }}
            >
              Based on this card
            </Button>
            <Button
              size="sm"
              mt={2}
              colorScheme="green"
              onClick={() => {
                close();
                resetCard();
              }}
            >
              From Scratch
            </Button>
          </PopoverFooter>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export const MobileUnsupported = () => (
  <div className={DEFAULT_FONT}>
    <Box height="100vh" w="100vw" bg={config.colorScheme["green"].bgGradient}>
      <Center w="100%" h="100%">
        <Text textAlign="center" color="white" fontSize="xl">
          We currently don't support edit on mobile devices.
        </Text>
      </Center>
    </Box>
  </div>
);

const COOKIES_KEY = "ziegler-labs-cards-cookies-acceptance";

export const Cookies = () => {
  const [cookiesAccepted, setCookiesAccepted] = useState(true);

  useEffect(() => {
    const cookiesAcceptance = localStorage.getItem(COOKIES_KEY) || false;
    setCookiesAccepted(cookiesAcceptance);
  }, []);

  if (cookiesAccepted) return null;

  return (
    <Box position="absolute" bottom={3} left={3} right={3} p={3} bg="#F5F5F5" borderRadius="3px">
      <Flex justify="space-between" align="center">
        <Box>
          <Text textAlign="justify">
            This website uses cookies to ensure you get the best experience.{" "}
            <Link fontWeight="semibold" isExternal href="http://privpolicy.zieglerlabs.com/">
              Privacy notice.
            </Link>
          </Text>
        </Box>
        <Box ml={3}>
          <Button
            onClick={() => {
              localStorage.setItem(COOKIES_KEY, true);
              setCookiesAccepted(true);
            }}
            colorScheme="green"
          >
            Allow Cookies
          </Button>
        </Box>
      </Flex>
    </Box>
  );
};
