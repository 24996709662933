import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from "@chakra-ui/react";

export const DeleteModal = ({ isOpen, onClose, documentId, onDelete, refetch }) => {
  const history = useHistory();
  const [deleteStatus, setDeleteStatus] = useState(null);

  const isDone = deleteStatus === "deleted";

  const onCloseHandler = () => (isDone ? refetch() : onClose());

  const onSubmit = () => {
    setDeleteStatus("waiting");
    onDelete().then(({ deleted }) => setDeleteStatus("deleted"));
  };

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onCloseHandler}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete Card</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{isDone ? "Card is deleted" : "Are you sure you want to delete this card ?"}</ModalBody>
        <ModalFooter>
          {!isDone && (
            <Button
              colorScheme="red"
              variant="solid"
              onClick={onSubmit}
              isLoading={deleteStatus === "waiting"}
              loadingText="Deleting"
            >
              Delete
            </Button>
          )}
          <Button variant="ghost" onClick={onCloseHandler}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
