import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { Box, Flex, Center, Button, Fade, Slide, Text } from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";

import { DEFAULT_FONT, API_URL } from "../constants";
import { CardWrapperFlip } from "../components/card-wrapper";
import { EditBar } from "../components/edit-bar";
import { ShareModal } from "../components/share-modal";
import { DeleteModal } from "../components/delete-alert-modal";

import { CreateNewButton } from "../components/common";

import { LoginAlertModal } from "../components/login-alert-modal";
// CarouselView
import config from "../config";

// Auth0
import { useAuth0 } from "@auth0/auth0-react";

const getInitialCardState = (index, lang, signature, logo) => {
  return {
    front: {
      cardTemplate: index,
      colorScheme: "green",
      font: DEFAULT_FONT,
      header: config.headings[0][lang],
      language: lang,
      logo
    },
    back: {
      font: DEFAULT_FONT,
      greeting: config.translations.greeting[lang],
      name: "",
      body: config.textBodies[0][lang],
      signature: signature
    }
  };
};

export const EditView = props => {
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();

  const cardIndex = location.state ? location.state.cardNo : 0;
  const cardLang = location.state ? location.state.cardLang : "en";
  const company = location.state ? location.state.logo : "ZIEGLER";
  const userName = user ? user.name : "";

  const initialCardState = getInitialCardState(cardIndex, cardLang, userName, company);

  const [front, setFront] = useState({
    ...initialCardState.front
  });

  const [back, setBack] = useState({
    ...initialCardState.back
  });

  const [errorMsg, setErrorMsg] = useState(null);

  const [isCardFlipped, setCardFlipped] = useState(0);
  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();
  const { isOpen: isDeleteModalOpen, onOpen: onDeleteModalOpen, onClose: onDeleteModalClose } = useDisclosure();

  const setValue = fn => (key, value) => fn(prevState => ({ ...prevState, [key]: value }));

  const resetState = () => {
    setFront(initialCardState.front);
    setBack(initialCardState.back);
    history.push(`/`);
  };

  const copyState = () => {
    history.push(`/e`);
  };

  const saveCard = async () => {
    const token = await getAccessTokenSilently();
    fetch(`${API_URL}/card`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token
      },
      body: JSON.stringify({ event: config.eventName, owner: user.email, content: { front, back } })
    })
      .then(res => res.json())
      .then(({ id }) => {
        history.push(`/e/${id}`);
        onModalOpen();
      })
      .catch(error => {
        console.log(error);
        alert("Unauthorized access");
      });
  };

  const sendMail = async ({ toEmail }) => {
    const token = await getAccessTokenSilently();
    return fetch(`${API_URL}/send/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token
      },
      body: JSON.stringify({ to: toEmail })
    })
      .then(res => res.json())
      .then(({ emailSent }) => {
        return emailSent;
      })
      .catch(() => {
        alert("Unauthorized access");
      });
  };

  const getEditableCard = async () => {
    // const token = await getAccessTokenSilently();
    fetch(`${API_URL}/card/${id}`)
      .then(resp => {
        if (resp.status === 200) {
          setErrorMsg(null);
          return resp.json();
        }
        if (resp.status === 404) {
          setErrorMsg("Sorry, we could not find you card.");
          throw Error("404");
        }
      })
      .then(data => {
        if (data.owner !== user.email) {
          setErrorMsg("You don't have permissions to edit this card.");
          return;
        }
        setBack(prevState => ({ ...prevState, ...data.content.back }));
        setFront(prevState => ({ ...prevState, ...data.content.front }));
      })
      .catch(err => {
        console.log(err);
      });
  };

  const onCardDelete = async () => {
    const token = await getAccessTokenSilently();
    return fetch(`${API_URL}/card/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token
      }
    })
      .then(res => {
        return res.json();
      })
      .catch(() => {
        alert("Unauthorized access");
      });
  };

  useEffect(() => {
    if (id && isAuthenticated) getEditableCard();
    if (!id && isAuthenticated) setBack(prev => ({ ...prev, signature: user.name }));
  }, [id, isAuthenticated, getAccessTokenSilently]);

  const isZiegler = front.logo === "ZIEGLER";

  return (
    <Box bg={config.colorScheme[front.colorScheme].bgGradient} w="100%" h="100vh">
      <Helmet>
        <title>Ziegler Card Service - Card Edit</title>
      </Helmet>
      {errorMsg ? (
        <>
          <Center h="100%">
            <Flex direction="column">
              <Text color="white" fontSize="xl">
                {errorMsg}
              </Text>
              <Center mt={3}>
                <Button colorScheme="white" variant="outline" onClick={() => history.push("/")}>
                  Back to card select
                </Button>
              </Center>
            </Flex>
          </Center>
        </>
      ) : (
        <Box>
          <ShareModal isOpen={isModalOpen} onClose={onModalClose} documentId={id} sendMail={sendMail} />
          <DeleteModal
            isOpen={isDeleteModalOpen}
            onClose={onDeleteModalClose}
            documentId={id}
            onDelete={onCardDelete}
            refetch={getEditableCard}
          />
          <Fade unmountOnExit in={front.cardTemplate !== null}>
            <Flex>
              <CreateNewButton resetCard={resetState} copyCard={copyState} />
              <Center w="75%" minHeight="100vh" bg={config.colorScheme[front.colorScheme].bgGradient}>
                <CardWrapperFlip
                  cardTemplate={
                    front.cardTemplate !== null
                      ? config[isZiegler ? "cards" : "externalCards"][front.cardTemplate].getLayers(front.colorScheme)
                      : null
                  }
                  content={{ front, back }}
                  isCardFlipped={isCardFlipped}
                  setCardFlipped={setCardFlipped}
                  placeholder=""
                  flipOnClick={true}
                />
              </Center>

              <Slide direction="right" in={front.cardTemplate !== null} style={{ width: "25%" }}>
                <Box minHeight="100vh" bg="gray.50" boxShadow="xs" height="100vh" overflow="auto">
                  <EditBar
                    editable={!id}
                    copyState={copyState}
                    handleChange={{ setFrontValue: setValue(setFront), setBackValue: setValue(setBack) }}
                    onClickShare={() => (!id ? saveCard() : onModalOpen())}
                    isCardFlipped={isCardFlipped}
                    setCardFlipped={setCardFlipped}
                    isZiegler={isZiegler}
                    content={{ front, back }}
                    lang={cardLang}
                    onDelete={onDeleteModalOpen}
                  />
                </Box>
              </Slide>
            </Flex>
          </Fade>
        </Box>
      )}
    </Box>
  );
};
