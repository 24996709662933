import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Flex,
  Spacer,
  Button,
  useClipboard,
  Input,
  InputGroup,
  InputRightElement,
  FormControl,
  FormLabel,
  FormErrorMessage,
  VisuallyHidden
} from "@chakra-ui/react";

import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";

import { CopyIcon } from "@chakra-ui/icons";

import SentImage from "../assets/sent.svg";

import { FRONTEND_URL } from "../constants";

export const ShareModal = ({ isOpen, onClose, documentId, sendMail }) => {
  const { register, handleSubmit, errors } = useForm();
  const { onCopy } = useClipboard(`${FRONTEND_URL}/v/${documentId}`);
  const [sendStatus, setSendStatus] = useState(null);

  useEffect(() => {
    setSendStatus(null);
  }, [isOpen]);

  const onSubmit = ({ toEmail }) => {
    setSendStatus("sending");
    sendMail({ toEmail }).then(({ isSent }) => {
      setSendStatus("sent");
    });
  };

  const formEmailValidator = {
    required: true,
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: "Entered value does not match email format"
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />

        <ModalBody>
          <Tabs variant="soft-rounded" colorScheme="green" align="center">
            <TabList mt={3}>
              <Tab>Card Link</Tab>
              <Tab>E-Mail</Tab>
            </TabList>
            <TabPanels h="300px">
              <TabPanel align="left">
                <FormLabel fontSize="lg">Card Link</FormLabel>
                <Text fontSize="md" mb="2">
                  You can send this link directly to your card's addressee.
                </Text>
                <InputGroup size="lg">
                  <Input value={`${FRONTEND_URL}/v/${documentId}`} placeholder="id to share" />

                  <InputRightElement cursor="pointer" children={<CopyIcon onClick={onCopy} color="gray.400" />} />
                </InputGroup>
              </TabPanel>
              <TabPanel align="left">
                {sendStatus !== "sent" ? (
                  <>
                    <Text fontSize="lg" mb="2">
                      Send using e-mail
                    </Text>
                    <Text fontSize="md" mb="2">
                      Using this form you can send card through e-mail to the receiver.
                    </Text>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <FormControl mt={4} isInvalid={errors.toEmail}>
                        <FormLabel htmlFor="toEmail">Your card's addressee e-mail</FormLabel>
                        <Input size="lg" name="toEmail" ref={register(formEmailValidator)} />
                        <FormErrorMessage>{errors.toEmail && errors.toEmail.message}</FormErrorMessage>
                      </FormControl>

                      <VisuallyHidden>
                        <FormControl mt={4} isInvalid={errors.name}>
                          <FormLabel htmlFor="name">Name</FormLabel>
                          <Input size="lg" name="name" ref={register({ maxLength: 1 })} />
                        </FormControl>

                        <FormControl mt={4} isInvalid={errors.surname}>
                          <FormLabel htmlFor="surname">Surname</FormLabel>
                          <Input size="lg" name="surname" ref={register({ maxLength: 1 })} />
                        </FormControl>
                      </VisuallyHidden>

                      <Flex pb={4} justify="end">
                        <Spacer />
                        <Button
                          mt={4}
                          variantColor="green"
                          type="submit"
                          isLoading={sendStatus === "sending"}
                          loadingText="Sending"
                        >
                          Send
                        </Button>
                      </Flex>
                    </form>
                  </>
                ) : (
                  <Flex h="100%" direction="column" justify="center" align="center">
                    <img src={SentImage} style={{ width: 80, margin: "20px 0 20px 0" }} />
                    <p>Thank you!</p>
                    <p>Your card was successfully sent.</p>
                  </Flex>
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

//<Flex direction="column" mb={2}>
//  <FormLabel mb="2">Preview link</FormLabel>
//  <InputGroup size="lg">
//    <Input value={`http://cards.zieglerlabs.com/v/${documentId}`} placeholder="id to share" />
//
//    <InputRightElement cursor="pointer" children={<CopyIcon onClick={onCopy} color="gray.400" />} />
//  </InputGroup>
//</Flex>
//
//<form onSubmit={handleSubmit(onSubmit)}>
//  <FormControl mt={4} isInvalid={errors.fromEmail}>
//    <FormLabel htmlFor="fromEmail">Your Email</FormLabel>
//    <Input size="lg" name="fromEmail" ref={register(formEmailValidator)} />
//    <FormErrorMessage>{errors.fromEmail && errors.fromEmail.message}</FormErrorMessage>
//  </FormControl>
//  <FormControl mt={4} isInvalid={errors.toEmail}>
//    <FormLabel htmlFor="toEmail">Receiver Email</FormLabel>
//    <Input size="lg" name="toEmail" ref={register(formEmailValidator)} />
//    <FormErrorMessage>{errors.fromEmail && errors.fromEmail.message}</FormErrorMessage>
//  </FormControl>
//
//  <VisuallyHidden>
//    <FormControl mt={4} isInvalid={errors.name}>
//      <FormLabel htmlFor="name">Name</FormLabel>
//      <Input size="lg" name="name" ref={register({ maxLength: 1 })} />
//    </FormControl>
//
//    <FormControl mt={4} isInvalid={errors.surname}>
//      <FormLabel htmlFor="surname">Surname</FormLabel>
//      <Input size="lg" name="surname" ref={register({ maxLength: 1 })} />
//    </FormControl>
//  </VisuallyHidden>
//
//  <Flex pb={4} justify="end">
//    <Spacer />
//    <Button mt={4} variantColor="green" type="submit">
//      Submit
//    </Button>
//  </Flex>
//</form>
