import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams, useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { Box, Flex, Center, Select, Tag, TagLabel, Fade, Slide, Text } from "@chakra-ui/react";
import Carousel, { Dots, slidesToShowPlugin } from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";

import { DEFAULT_FONT } from "../constants";
import { CardWrapperFlip } from "../components/card-wrapper";
import { Auth0LoginButton } from "../components/login-button";
import { Auth0LogoutButton } from "../components/logout-button";
import { LoginAlertModal } from "../components/login-alert-modal";
import { LanguageSelector } from "../components/lang-selector";

import config from "../config";
import { CompanySelector } from "../components/comp-selector";

import { isZiegler } from "../utils";

const CardSelector = () => {
  const history = useHistory();

  const [selectedCard, setSelectedCard] = useState(0);
  const [selectedLang, setSelectedLang] = useState("en");
  const [selectedCompany, setSelectedCompany] = useState("ZIEGLER");
  const [isCardFlipped, setCardFlipped] = useState(0);

  const { user, isAuthenticated } = useAuth0();

  return (
    <Box bg={config.colorScheme.green.bgGradient} w="100%" height="100vh">
      <Helmet>
        <title>Ziegler Card Service - Card Select</title>
      </Helmet>
      <Box w="30%" position="absolute" left="2" top="2" zIndex="100">
        <Fade unmountOnExit in={isAuthenticated === false}>
          <Auth0LoginButton />
        </Fade>
        <Fade unmountOnExit in={isAuthenticated === true}>
          <Flex>
            <Tag size={"lg"} borderRadius="full" variant="solid" colorScheme="teal">
              Hello {isAuthenticated ? user.name : ""}!
            </Tag>
            <Auth0LogoutButton />
          </Flex>
        </Fade>
      </Box>
      <Flex position="absolute" top={2} right={2} zIndex={10} alignItems="center">
        <Box mr={4}>
          <CompanySelector setSelectedCompany={setSelectedCompany} selectedCompany={selectedCompany} />
        </Box>
        <LanguageSelector selectedLang={selectedLang} onLangChange={setSelectedLang} />
      </Flex>
      <Carousel
        slidesPerPage={2}
        centered
        lazyLoad
        clickToChange
        value={selectedCard}
        onChange={index => {
          setSelectedCard(index);
          if (index !== selectedCard) setCardFlipped(0);
        }}
      >
        {config[isZiegler(selectedCompany) ? "cards" : "externalCards"].map(
          ({ getLayers, textBody, heading }, index) => {
            const front = {
              cardTemplate: index,
              colorScheme: "green",
              font: DEFAULT_FONT,
              header: config.headings[0][selectedLang],
              logo: selectedCompany
            };
            const back = {
              font: DEFAULT_FONT,
              greeting: config.translations.greeting[selectedLang],
              name: "",
              body: config.textBodies[0][selectedLang],
              signature: user ? user.name : ""
            };
            const isSelected = index === selectedCard;
            return (
              <Flex
                key={index}
                direction="column"
                justify="center"
                align="center"
                w="100%"
                minHeight="100vh"
                onClick={() => {}}
              >
                <CardWrapperFlip
                  cardTemplate={getLayers("green")}
                  content={{ front, back }}
                  isCardFlipped={isSelected ? isCardFlipped : false}
                  placeholder=""
                  setCardFlipped={isSelected ? setCardFlipped : null}
                  flipOnClick={isSelected}
                />
                <Fade in={isSelected}>
                  <Center mt={4}>
                    <Tag
                      size={"lg"}
                      borderRadius="full"
                      variant="solid"
                      colorScheme="teal"
                      cursor="pointer"
                      onClick={() => {
                        history.push({
                          pathname: `/e/`,
                          state: { cardNo: index, cardLang: selectedLang, logo: selectedCompany }
                        });
                      }}
                    >
                      <TagLabel>Select This Card</TagLabel>
                    </Tag>
                  </Center>
                </Fade>
              </Flex>
            );
          }
        )}
      </Carousel>
    </Box>
  );
};

export const SelectView = () => {
  return (
    <Box bg={config.colorScheme.green.bgGradient} w="100%" h="100vh">
      <CardSelector />
    </Box>
  );
};
