import React, { useEffect, useState } from "react";
import { Flex, Box, Button, Center, Tag, TagLabel } from "@chakra-ui/react";

import enFlag from "../assets/lng/en.svg";
import deFlag from "../assets/lng/de.svg";
import nlFlag from "../assets/lng/nl.svg";
import frFlag from "../assets/lng/fr.svg";
import chFlag from "../assets/lng/ch.svg";
import chTradFlag from "../assets/lng/ch2.svg";
import plFlag from "../assets/lng/pl.svg";

const SUPPORTED_LANGUAGES = [
  { short: "en", full: "English", icon: enFlag },
  { short: "de", full: "Deutsch", icon: deFlag },
  { short: "nl", full: "Dutch", icon: nlFlag },
  { short: "fr", full: "French", icon: frFlag },
  // { short: "chSimplify", full: "Chinese Simplify", icon: chFlag },
  // { short: "ch", full: "Chinese Traditional", icon: chTradFlag },
  // { short: "pl", full: "Polish", icon: plFlag }
];

const ICON_SIZE = 30;
const ICON_MARGIN = 10;

export const LanguageSelector = ({ selectedLang, onLangChange }) => {
  return (
    <Flex
      justify="space-between"
      w={`${SUPPORTED_LANGUAGES.length * (ICON_SIZE + ICON_MARGIN)}px`}
      p={2}
      bg="#099191"
      borderRadius={5}
      boxShadow="sm"
    >
      {SUPPORTED_LANGUAGES.map(({ short, full, icon }) => (
        <Box key={short} w={`${ICON_SIZE}px`} h={`${ICON_SIZE}px`} cursor="pointer" onClick={() => onLangChange(short)}>
          <img
            alt={`${full} Language`}
            src={icon}
            style={{ width: "100%", height: "100%", opacity: selectedLang === short ? 1 : 0.5 }}
          />
        </Box>
      ))}
    </Flex>
  );
};
