import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from "@chakra-ui/react";
// import { LoginButton } from "./login-button";
import { Auth0LoginButton } from "./login-button";

export const LoginAlertModal = ({ isLoginAlertVisible, onClose }) => {
  return (
    <Modal closeOnOverlayClick={false} isOpen={isLoginAlertVisible} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Please Login</ModalHeader>
        <ModalCloseButton />
        <ModalBody>Please login to edit selected card.</ModalBody>
        <ModalFooter>
          <Auth0LoginButton />
          <Button variant="ghost" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
