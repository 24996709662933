import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { Box, Flex, Center, Text, Button, ButtonGroup, IconButton, Textarea } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { CardWrapperFlip } from "../components/card-wrapper";
import Snowfall from "react-snowfall";

import { ZieglerLabsLogo } from "../components/common";
import config from "../config";
import { API_URL } from "../constants";

export const Preview = () => {
  const { id } = useParams();
  const history = useHistory();
  const [front, setFront] = useState(null);
  const [back, setBack] = useState(null);
  const [isCardFlipped, setCardFlipped] = useState(false);

  const [errorMsg, setErrorMsg] = useState(null);

  const getCard = async () => {
    fetch(`${API_URL}/card/${id}`)
      .then(resp => {
        if (resp.status === 200) {
          setErrorMsg(null);
          return resp.json();
        }
        if (resp.status === 404) {
          setErrorMsg("Sorry, we could not find you card.");
          throw Error("404");
        }
      })
      .then(data => {
        setBack(prevState => ({ ...prevState, ...data.content.back }));
        setFront(prevState => ({ ...prevState, ...data.content.front }));
      })
      .catch(err => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    if (id) {
      getCard();
    }
  }, [id]);

  if (!front || !back) {
    return (
      <Center w="100%" minHeight="100vh" bg={config.colorScheme.green.bgGradient}>
        {errorMsg && (
          <>
            <Center h="100%">
              <Flex direction="column">
                <Text color="white" fontSize="xl">
                  {errorMsg}
                </Text>
                <Center mt={3}>
                  <Button colorScheme="white" variant="outline" onClick={() => history.push("/")}>
                    Back to card select
                  </Button>
                </Center>
              </Flex>
            </Center>
          </>
        )}
      </Center>
    );
  }

  const isZiegler = front.logo === "ZIEGLER";

  return (
    <Center w="100%" minHeight="100vh" bg={config.colorScheme[front.colorScheme].bgGradient}>
      <Helmet>
        <title>
          Ziegler Card Service - Card from {back.signature} to {back.name}
        </title>
      </Helmet>
      <Snowfall />
      <CardWrapperFlip
        cardTemplate={config[isZiegler ? "cards" : "externalCards"][front.cardTemplate].getLayers(front.colorScheme)}
        content={{ front, back }}
        isCardFlipped={isCardFlipped}
        setCardFlipped={setCardFlipped}
        showName={back.name.length > 0}
        flipOnClick={true}
      />
      <ZieglerLabsLogo />
    </Center>
  );
};
