import { Box, Menu, MenuButton, MenuList, MenuItemOption, MenuOptionGroup, Button } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

import config from "../config";
import { COMPANIES_LIST } from "../constants";

export const CompanySelector = ({ setSelectedCompany, selectedCompany }) => {
  return (
    <Box>
      <Menu w={200}>
        <MenuButton colorScheme="teal" _focus="none" as={Button} rightIcon={<ChevronDownIcon />}>
          {selectedCompany}
        </MenuButton>
        <MenuList
          bg={config.colorScheme.green.bgGradient}
          color="white"
          borderColor="#0a6655"
          boxShadow="0px 2px 5px rgba(0,0,0,0.3)"
        >
          <MenuOptionGroup value={selectedCompany} title="Company" type="radio" onChange={setSelectedCompany}>
            {COMPANIES_LIST.map((provider, idx) => (
              <MenuItemOption key={`menu-company-${idx}`} value={provider} _focus={{ bg: "#285E61" }}>
                {provider}
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    </Box>
  );
};
