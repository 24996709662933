import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { Box, useBreakpointValue } from "@chakra-ui/react";

import { ProtectedRoute } from "./components/protected-route";
import { SelectView } from "./views/select-view";
import { EditView } from "./views/edit-view";
import { Preview } from "./views/preview";
import { Stats } from "./views/stats";
import { MobileUnsupported, ZieglerLabsLogo, Cookies } from "./components/common";

const NoMobile = ({ children }) => {
  const mobileBreakpoint = useBreakpointValue({ base: false, lg: true });
  if (mobileBreakpoint) {
    return children;
  }
  return <MobileUnsupported />;
};

const App = () => {
  const [comp, setComp] = useState("ziegler");
  return (
    <Box w="100%" minHeight="100vh">
      <Router>
        <Switch>
          <Route exact path="/v/:id">
            <Preview />
          </Route>
          <Route exact path="/stats">
            <Stats />
          </Route>
          <Route exact path="/">
            <NoMobile>
              <SelectView />
            </NoMobile>
          </Route>
          <ProtectedRoute exact path="/e/">
            <NoMobile>
              <EditView />
            </NoMobile>
          </ProtectedRoute>
          <ProtectedRoute exact path="/e/:id">
            <NoMobile>
              <EditView />
            </NoMobile>
          </ProtectedRoute>
        </Switch>
      </Router>
      <ZieglerLabsLogo />
      <Cookies />
    </Box>
  );
};

export default App;
//<EditView />
