import React from "react";
import { GoogleLogin } from "react-google-login";
import { Tag, TagLabel } from "@chakra-ui/react";
import { useAuth0 } from "@auth0/auth0-react";

export const LoginButton = ({ clientId, allowedDomain, loginSuccess, loginFailure }) => {
  return (
    <GoogleLogin
      clientId={clientId}
      hostedDomain={allowedDomain}
      render={renderProps => (
        <Tag
          size={"lg"}
          borderRadius="full"
          variant="solid"
          colorScheme="teal"
          cursor="pointer"
          onClick={renderProps.onClick}
          disabled={renderProps.disabled}
        >
          <TagLabel>Login</TagLabel>
        </Tag>
      )}
      buttonText="Login"
      onSuccess={loginSuccess}
      onFailure={loginFailure}
      cookiePolicy={"single_host_origin"}
      style={{ marginTop: "100px" }}
      isSignedIn={true}
    />
  );
};

export const Auth0LoginButton = () => {
  const { loginWithPopup, loginWithRedirect } = useAuth0();

  // return <button onClick={() => loginWithRedirect()}>Log In</button>;
  return (
    <Tag
      size={"lg"}
      borderRadius="full"
      variant="solid"
      colorScheme="teal"
      cursor="pointer"
      onClick={() => loginWithRedirect()}
    >
      <TagLabel>Login</TagLabel>
    </Tag>
  );
};
