import config from "./config";

export const DEFAULT_FONT = "lato";
export const ZIEGLER_COMPANY = "ZIEGLER";
export const COMPANIES_LIST = [ZIEGLER_COMPANY, ...config.companies];

export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;
export const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;

export const API_URL = process.env.REACT_APP_API_URL;
export const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;
