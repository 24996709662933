import React from "react";
import { Route, useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { LoginAlertModal } from "./login-alert-modal";

export const ProtectedRoute = ({ children, ...args }) => {
  const history = useHistory();
  const { isAuthenticated, isLoading } = useAuth0();

  return (
    <Route {...args}>
      <LoginAlertModal
        isLoginAlertVisible={!isLoading && !isAuthenticated}
        onClose={() => {
          history.push("/");
        }}
      />
      {children}
    </Route>
  );
};
