const config = {
  eventName: "christmas2022",
  colorScheme: {
    green: {
      bgGradient: "linear-gradient(#048d74, #048d7c)",
      bgBack: "#ffffff",
      fontColor: "#04695C"
    },
    ocean: {
      bgGradient: "linear-gradient(#048d85, #048d8D)",
      bgBack: "#ffffff",
      fontColor: "#04695C"
    }
  },
  companies: ["TRANSCO", "TRANS SERVICE", "SATRA", "CHATEL", "SPECIAL REQUEST BY ZIEGLER", "INTERTRANS"],
  textBodies: [
    {
      en: `As the festive season draws near, we would like to express our gratitude for another year of cooperation together. From every one of us here at Ziegler Group, we wish you a well-deserved time of relaxation and a new year filled with happiness and health.`,
      de: `Die Festtage stehen vor der Tür und wir möchten uns für ein weiteres Jahr der Zusammenarbeit bedanken. Im Namen der gesamten Ziegler-Gruppe wünschen wir Ihnen eine wohlverdiente Zeit der Entspannung und ein neues Jahr voller Glück und Gesundheit.`,
      fr: `À l'approche des fêtes de fin d'année, nous tenons à exprimer notre gratitude pour cette nouvelle année passée ensemble. Au nom de tous les membres du groupe Ziegler, nous vous souhaitons une période de détente bien méritée et une nouvelle année remplie de bonheur et de santé.`,
      nl: `Nu de feestdagen dichterbij komen, willen wij onze dank uitspreken voor wederom een jaar van samenwerking. Namens ieder van ons hier bij de Ziegler Group wensen wij u een welverdiende tijd van ontspanning en een nieuw jaar vol geluk en gezondheid.`
    },
    {
      en: `As we welcome another year, we are excited about the opportunities that await us and we are anticipating the chance to unveil even more exciting developments in 2024.

      Here's to a festive season filled with joy, and may the New Year bring you prosperity and fulfilment.`,
      de: `Wir begrüßen ein neues Jahr und freuen uns auf die Möglichkeiten, die sich uns bieten und auf weitere aufregende Entwicklungen in 2024.

      Wir wünschen Ihnen ein Fest voller Freude und ein neues Jahr, das Ihnen Wohlstand und Erfüllung bringen möge.`,
      fr: `Alors que nous entamons une nouvelle année, nous nous réjouissons des opportunités qui s'offrent à nous et avons hâte de vous dévoiler nos futurs développements, encore plus passionnants en 2024.

      Nous vous souhaitons des fêtes de fin d'année pleines de joie. Que la nouvelle année vous apporte prospérité et épanouissement.`,
      nl: `Nu we weer een nieuw jaar verwelkomen, zijn we enthousiast over de kansen die ons te wachten staan en anticiperen we op de kans om in 2024 nog spannendere ontwikkelingen te onthullen.

      Op feestdagen vol vreugde, en moge het nieuwe jaar u voorspoed en vervulling brengen.`
    },
    {
      en: `In this special season, we take a moment to ponder things that matter most to us, such as our partnership with you. We send you our warmest gratitude for another year of fruitful partnership and collaboration.

      As the holiday season unfolds, we hope you enjoy  your well-earned time of rest and joyful celebration. And looking ahead to the coming year, may it be marked by many new achievements and shared accomplishments.`,
      de: `In dieser besonderen Jahreszeit nehmen wir uns einen Moment Zeit, um uns auf die Dinge zu besinnen, die uns am wichtigsten sind, wie unsere Partnerschaft mit Ihnen. Wir danken Ihnen von Herzen für ein weiteres Jahr fruchtbarer Partnerschaft und Zusammenarbeit.

      Wir hoffen, dass Sie in der Weihnachtszeit die wohlverdiente Zeit der Ruhe und des fröhlichen Feierns genießen können. Und mit Blick auf das kommende Jahr wünschen wir Ihnen, dass es von vielen neuen Erfolgen und gemeinsamen Errungenschaften geprägt sein wird.`,
      fr: `En cette période particulière, nous prenons le temps de réfléchir aux choses qui comptent le plus pour nous, comme notre partenariat. Nous vous remercions chaleureusement pour cette nouvelle année de collaboration fructueuse.

      En cette période de fêtes de fin d'année, nous espérons que vous profiterez de ce temps de repos bien mérité et de ces joyeuses célébrations. Nous espérons que l'année à venir sera marquée par de nouvelles réalisations ensemble.`,
      nl: `In dit bijzondere seizoen nemen we even de tijd om na te denken over de zaken die voor ons het belangrijkst zijn, zoals onze samenwerking met u. Wij sturen u onze hartelijke dank voor wederom een jaar van vruchtbare samenwerking.

      Terwijl de feestdagen zich ontvouwen, hopen we dat u geniet van uw welverdiende tijd van rust en vreugdevolle vieringen. En als we vooruitkijken naar het komende jaar, moge dit gekenmerkt worden door het behalen van nieuwe doelen en gedeelde prestaties.`
    },
    {
      en: `Ziegler sends you our sincerest well wishes. May this festive season overflow with joy, and as we step into the New Year, may it be brimming with well-deserved successes. We look forward to sharing another year of collaboration and partnership with you in the new year.`,
      de: `Ziegler wünscht Ihnen von ganzem Herzen alles Gute. Mögen die Festtage von Freude erfüllt sein und das neue Jahr von wohlverdienten Erfolgen geprägt sein. Wir freuen uns auf ein weiteres Jahr der Zusammenarbeit und Partnerschaft mit Ihnen im neuen Jahr.`,
      fr: `Ziegler vous présente ses meilleurs vœux. Que ces fêtes de fin d'année vous remplissent de joie et qu'à l'aube de la nouvelle année, celle-ci soit remplie de succès bien mérités. Nous nous réjouissons de partager avec vous une nouvelle année de collaboration.`,
      nl: `Ziegler stuurt u onze oprechte gelukwensen. Moge de feestdagen overlopen van vreugde, en als we het nieuwe jaar instappen, mag het vol welverdiende successen zijn. We kijken ernaar uit om in het nieuwe jaar weer een jaar van samenwerking en partnerschap met u te delen.`
    },
    {
      en: `As the holiday season unfolds, we want to extend our most sincere greetings and well wishes to you. We hope that your holidays are brimming with happiness and moments of joy.

      Stepping into the New Year, we fervently wish for your success, both in significant milestones as well as the small joys that make life truly fulfilling.`,
      de: `Zum Beginn der Weihnachtszeit möchten wir Ihnen unsere herzlichsten Grüße senden. Wir hoffen, dass Ihre Feiertage voller Glück und Momente der Freude sind.

      Für das neue Jahr wünschen wir Ihnen von Herzen viel Erfolg, sowohl bei wichtigen Meilensteinen als auch bei den kleinen Freuden, die das Leben wahrhaft erfüllend machen.`,
      fr: `À l'occasion des fêtes de fin d'année, nous tenons à vous adresser nos plus sincères salutations et nos meilleurs vœux. Nous espérons que cette fin d’année sera pleine de bonheur et de moments de joie.

      À l'aube de la nouvelle année, nous souhaitons ardemment votre réussite, qu'il s'agisse d'étapes importantes ou de petits bonheurs du quotidien.`,
      nl: `Terwijl de feestdagen zich ontvouwen, willen wij u onze meest oprechte groeten en goede wensen overbrengen. Wij hopen dat uw vakantie vol geluk en momenten van vreugde zal zijn.

      Als we het nieuwe jaar ingaan, wensen we vurig uw succes, zowel wat betreft belangrijke mijlpalen als de kleine vreugden die het leven echt vervullend maken.`
    }
  ],
  headings: [
    {
      en: "Merry Christmas",
      de: "Frohe Weihnachten",
      fr: "Joyeux Noël",
      nl: "Vrolijk Kerstfeest"
    },
    {
      en: "Happy New Year",
      de: "Ein glückliches neues Jahr",
      fr: "Bonne Année",
      nl: "Gelukkig Nieuwjaar"
    },
    {
      en: "Merry Christmas and a Happy New Year",
      de: "Fröhliche Weihnachten und ein gutes neues Jahr",
      fr: "Joyeux Noël et bonne année",
      nl: "Fijne kerstdagen en gelukkig Nieuwjaar"
    },
    {
      en: "Happy Holidays",
      de: "Frohe Feiertage",
      fr: "Bonnes vacances",
      nl: "Fijne feestdagen"
    }
  ],
  cards: [
    {
      getLayers: colorScheme => [`card1_layer1.jpg`]
    },
    {
      getLayers: colorScheme => [`card2_layer1.jpg`]
    },
    {
      getLayers: colorScheme => [`card3_layer1.jpg`]
    },
    {
      getLayers: colorScheme => [`card4_layer1.jpg`]
    },
    {
      getLayers: colorScheme => [`card5_layer1.jpg`]
    },
  ],
  externalCards: [
    {
      getLayers: colorScheme => [`card1_layer1.jpg`]
    },
    {
      getLayers: colorScheme => [`card2_layer1.jpg`]
    },
    {
      getLayers: colorScheme => [`card3_layer1.jpg`]
    },
    {
      getLayers: colorScheme => [`card4_layer1.jpg`]
    },
    {
      getLayers: colorScheme => [`card5_layer1.jpg`]
    },
  ],
  translations: {
    greeting: {
      en: "Dear",
      fr: "Cher",
      de: "Sehr geehrte Frau",
      nl: "Beste"
    }
  }
};

export default config;
