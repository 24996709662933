import React from "react";
import { GoogleLogout } from "react-google-login";
import { Tag, TagLabel } from "@chakra-ui/react";
import { useAuth0 } from "@auth0/auth0-react";

export const LogoutButton = ({ clientId, allowedDomain, logout }) => {
  return (
    <GoogleLogout
      clientId={clientId}
      hostedDomain={allowedDomain}
      render={renderProps => (
        <Tag
          ml={2}
          size={"lg"}
          borderRadius="full"
          variant="solid"
          colorScheme="teal"
          cursor="pointer"
          onClick={renderProps.onClick}
          disabled={renderProps.disabled}
        >
          <TagLabel>Logout</TagLabel>
        </Tag>
      )}
      buttonText="Logout"
      onLogoutSuccess={logout}
    />
  );
};

export const Auth0LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <Tag
      ml={2}
      size={"lg"}
      borderRadius="full"
      variant="solid"
      colorScheme="teal"
      cursor="pointer"
      onClick={() => logout({ returnTo: window.location.origin })}
    >
      <TagLabel>Logout</TagLabel>
    </Tag>
  );
};
