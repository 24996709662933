import React, { useState } from "react";

import {
  Flex,
  Text,
  Button,
  IconButton,
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Input,
  Textarea,
  Tooltip
} from "@chakra-ui/react";

import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton
} from "@chakra-ui/react";
import { Select } from "@chakra-ui/react";

import { EmailIcon, LockIcon, UnlockIcon, RepeatIcon, DeleteIcon } from "@chakra-ui/icons";

import config from "../config";

const HEADER_MAX_LENGTH = 60;
const GREETING_MAX_LENGTH = 16;
const RECEIVER_MAX_LENGTH = 30;
const BODY_MAX_LENGTH = 550;
const SIGNATURE_MAX_LENGTH = 32;

const CharactersCounter = ({ value, maxLength }) => (
  <Text color="gray.400" ml={2}>
    [{value} / {maxLength}]
  </Text>
);

const FontSelect = ({ value, onChange, isDisabled }) => (
  <Select value={value} onChange={e => onChange(e.target.value)} size="lg" isDisabled={isDisabled}>
    <option value="lobster">Style 1</option>
    <option value="oleo">Style 2</option>
    <option value="galada">Style 3</option>
  </Select>
);

const FieldLabel = ({ children }) => (
  <Text mb={2} fontSize="lg">
    {children}
  </Text>
);

export const EditBar = ({
  editable,
  handleChange,
  onClickShare,
  onDelete,
  content,
  isCardFlipped,
  isZiegler,
  setCardFlipped,
  lang,
  copyState
}) => {
  const { front, back } = content;
  const { setFrontValue, setBackValue } = handleChange;

  const [tempHeaderPreset, setTempHeaderPreset] = useState(0);
  const [tempPreset, setTempPreset] = useState(front.cardTemplate + "");

  return (
    <>
      <Flex h="65px" borderBottomWidth="1px" borderColor="gray.300" align="center" justify="space-between" px="4">
        <Flex align="center" justify="center">
          <Tooltip
            label={
              editable
                ? "you can edit until you click publish"
                : "Click to unlock edit and create new card based on current one"
            }
          >
            <IconButton
              variant="outline"
              isDisabled={editable}
              mr={2}
              icon={editable ? <UnlockIcon /> : <LockIcon />}
              onClick={() => {
                if (!editable) {
                  copyState();
                }
              }}
            />
          </Tooltip>
          <Text fontSize="lg">Card Edit</Text>
        </Flex>
        <Button
          size="sm"
          colorScheme="green"
          variant="solid"
          onClick={() => {
            onClickShare();
          }}
        >
          {editable ? "Publish" : "Share"}
        </Button>
      </Flex>
      <Box p="4">
        <Tabs
          variant="soft-rounded"
          colorScheme="green"
          align="center"
          index={isCardFlipped}
          onChange={index => setCardFlipped(index)}
        >
          <TabList>
            <Tab>Front</Tab>
            <Tab>Back</Tab>
          </TabList>
          <TabPanels>
            <TabPanel align="left">
              {!isZiegler ? (
                <Box mt={4}>
                  <FieldLabel>Company</FieldLabel>
                  <Select
                    value={front.logo}
                    onChange={e => setFrontValue("logo", e.target.value)}
                    size="lg"
                    isDisabled={!editable}
                  >
                    {config.companies.map(o => (
                      <option value={o} key={o}>
                        {o}
                      </option>
                    ))}
                  </Select>
                </Box>
              ) : null}
              <Box mt={4}>
                <FieldLabel>Template</FieldLabel>
                <Select
                  value={front.cardTemplate}
                  onChange={e => {
                    setFrontValue("cardTemplate", parseInt(e.target.value));
                  }}
                  size="lg"
                  isDisabled={!editable}
                >
                  {config[isZiegler ? "cards" : "externalCards"].map((_, index) => (
                    <option key={`${isZiegler ? "ziegler" : "external"}-${index}`} value={index}>
                      Template {index + 1}
                    </option>
                  ))}
                </Select>
              </Box>
              {/* <Box mt={4}>
                <FieldLabel>Color Theme</FieldLabel>
                <Select
                  value={front.colorScheme}
                  onChange={e => setFrontValue("colorScheme", e.target.value)}
                  size="lg"
                  isDisabled={!editable}
                >
                  <option value="green">Green</option>
                  <option value="blue">Blue</option>
                  <option value="pink">Pink</option>
                  <option value="gold">Gold</option>
                </Select>
              </Box> */}
              {/* <Box mt={4}>
                <FieldLabel>
                  <Flex>Font</Flex>
                </FieldLabel>
                <FontSelect
                  value={front.font}
                  onChange={value => setFrontValue("font", value)}
                  isDisabled={!editable}
                />
              </Box> */}
              <Box mt={4}>
                <FieldLabel>Header Preset</FieldLabel>
                <Select
                  value={tempHeaderPreset}
                  onChange={e => {
                    const { value } = e.target;
                    setTempHeaderPreset(value);
                    if (value === "custom") return;
                    setFrontValue("header", config.headings[parseInt(e.target.value)][lang]);
                  }}
                  size="lg"
                  isDisabled={!editable}
                >
                  {config.headings.map((_, idx) => (
                    <option value={`${idx}`}>Preset {idx + 1}</option>
                  ))}
                  <option value="custom">Custom</option>
                </Select>
              </Box>
              <Box mt={4}>
                <FieldLabel>
                  <Flex>
                    Header
                    <CharactersCounter value={front.header.length} maxLength={HEADER_MAX_LENGTH} />
                  </Flex>
                </FieldLabel>
                <Input
                  value={front.header}
                  onChange={e => {
                    if (e.target.value.length <= HEADER_MAX_LENGTH) {
                      setFrontValue("header", e.target.value);
                      setTempHeaderPreset("custom");
                    }
                  }}
                  placeholder="Header"
                  size="lg"
                  isDisabled={!editable}
                />
              </Box>
              {!editable && (
                <Box mt={8}>
                  <FieldLabel>
                    <Flex>Permanently Delete This Card</Flex>
                  </FieldLabel>
                  <Button
                    w="100%"
                    mt={1}
                    leftIcon={<DeleteIcon />}
                    colorScheme="red"
                    variant="solid"
                    onClick={onDelete}
                  >
                    Delete
                  </Button>
                </Box>
              )}
            </TabPanel>
            <TabPanel align="left">
              {/*
              <Box mt={4}>
                <FieldLabel>Font</FieldLabel>
                <FontSelect value={back.font} onChange={value => setBackValue("font", value)} isDisabled={!editable} />
              </Box>
              */}
              <Box mt={4}>
                <FieldLabel>
                  <Flex>
                    Greeting <CharactersCounter value={back.greeting.length} maxLength={GREETING_MAX_LENGTH} />
                  </Flex>
                </FieldLabel>
                <Input
                  value={back.greeting}
                  onChange={e => {
                    if (e.target.value.length <= GREETING_MAX_LENGTH) setBackValue("greeting", e.target.value);
                  }}
                  placeholder="Greeting"
                  size="lg"
                  isDisabled={!editable}
                />
              </Box>
              <Box mt={4}>
                <FieldLabel>
                  <Flex>
                    Receiver name
                    <CharactersCounter value={back.name.length} maxLength={RECEIVER_MAX_LENGTH} />
                  </Flex>
                </FieldLabel>
                <Input
                  value={back.name}
                  onChange={e => {
                    if (e.target.value.length <= RECEIVER_MAX_LENGTH) setBackValue("name", e.target.value);
                  }}
                  placeholder="Receiver name"
                  size="lg"
                  isDisabled={!editable}
                />
              </Box>
              <Box mt={4}>
                <FieldLabel>Body Preset</FieldLabel>
                <Select
                  value={tempPreset}
                  onChange={e => {
                    const { value } = e.target;
                    setTempPreset(value);
                    if (value === "custom") return;
                    setBackValue("body", config.textBodies[parseInt(e.target.value)][lang]);
                  }}
                  size="lg"
                  isDisabled={!editable}
                >
                  {config.textBodies.slice(0, 5).map((_, idx) => (
                    <option value={`${idx}`} key={`config.bodies.${idx}`}>
                      Preset {idx + 1}
                    </option>
                  ))}
                  <option value="custom">Custom</option>
                </Select>
              </Box>
              <Box mt={4}>
                <Flex justify="space-between" align="center">
                  <FieldLabel>
                    <Flex>
                      Body <CharactersCounter value={back.body.length} maxLength={BODY_MAX_LENGTH} />
                    </Flex>
                  </FieldLabel>
                  {/*lang && (
                    <Popover placement="left">
                      <PopoverTrigger>
                        <IconButton size="xs" variant="outline" icon={<RepeatIcon />} isDisabled={!editable} />
                      </PopoverTrigger>
                      <PopoverContent>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverBody fontSize="md">
                          Are you sure you want to revert card's body to default value?
                        </PopoverBody>
                        <PopoverFooter>
                          <Button
                            size="sm"
                            colorScheme="green"
                            onClick={() => setBackValue("body", config.textBodies[parseInt(tempPreset)][lang])}
                          >
                            From Scratch
                          </Button>
                        </PopoverFooter>
                      </PopoverContent>
                    </Popover>
                  )*/}
                </Flex>
                <Textarea
                  value={back.body}
                  onChange={e => {
                    if (e.target.value.length <= BODY_MAX_LENGTH) {
                      setBackValue("body", e.target.value);
                      setTempPreset("custom");
                    }
                  }}
                  placeholder="Card Body"
                  rows={8}
                  size="lg"
                  isDisabled={!editable}
                />
              </Box>
              <Box mt={4}>
                <FieldLabel display="flex">
                  <Flex>
                    Signature <CharactersCounter value={back.signature.length} maxLength={32} />
                  </Flex>
                </FieldLabel>
                <Input
                  value={back.signature}
                  onChange={e => {
                    if (e.target.value.length <= SIGNATURE_MAX_LENGTH) setBackValue("signature", e.target.value);
                  }}
                  placeholder="Card Signature"
                  size="lg"
                  isDisabled={!editable}
                />
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </>
  );
};
//<CharactersCounter value={back.signature.length} maxLength={SIGNATURE_MAX_LENGTH} />
