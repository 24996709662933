import React, { useEffect, useState } from "react";
import { Box, Flex, Center, Button, Fade, Slide, Text } from "@chakra-ui/react";
import { useAuth0 } from "@auth0/auth0-react";

import config from "../config";

export const Stats = () => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [numOfCards, setNumOfCards] = useState(0);

  useEffect(() => {
    const getCardStats = async () => {
      return fetch(`/api/check-stats`, {
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(res => {
          return res.json();
        })
        .then(({ numOfCards }) => setNumOfCards(numOfCards))
        .catch(() => {
          alert("Unauthorized access");
        });
    };

    getCardStats();
  }, []);

  return (
    <Box height="100vh" width="100%" bg={config.colorScheme["green"].bgGradient}>
      <Center height="100%" color="white" fontSize="xl">
        Number of published cards: {numOfCards}
      </Center>
    </Box>
  );
};
