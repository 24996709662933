import { extendTheme } from "@chakra-ui/react";

const colors = {
  brand: {
    900: "#1a365d",
    800: "#153e75",
    700: "#2a69ac"
  }
};

const textStyles = {
  h3: {
    fontSize: ["18px", "22px"],
    fontWeight: "semibold",
    lineHeight: "110%",
    letterSpacing: "-1%"
  }
};

export default extendTheme({ colors, textStyles });
